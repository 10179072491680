// External Imports
import React, { Component } from 'react';
// External Components
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const styles = theme => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  },
  statusContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    margin: theme.spacing(2)
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    alignSelf: 'center'
  }
});

class Loading extends Component {
  render() {
    return (
      <Paper className={this.props.classes.statusContainer}>
        <Typography
          variant="h6"
          color="inherit"
          className={this.props.classes.title}
        >
          {this.props.message}
        </Typography>
        <CircularProgress className={this.props.classes.progress} size={50} />
      </Paper>
    );
  }
}
Loading.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string,
}
export default withStyles(styles)(Loading);
