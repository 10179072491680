import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CompanyRoles from './companyRoles';
import OpUnitRoles from './opUnitRoles';
import Loading from '../common/loading';
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5)
  }
});

const GET_OP_UNITS = gql`
  query OpUnits {
    opUnits {
      id
      name
    }
  }
`;

class ManageCompany extends React.Component {
  state = {
    value: 0,
    opUnit: 0
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleOpUnitChange = (event, index) => {
    this.setState({ opUnit: index });
  };
  renderOpUnitRoles = opUnitId => {
    return <OpUnitRoles opUnitId={opUnitId} />;
  };
  renderOpUnitTabs = opUnits =>
    opUnits.map((opUnit, index) => <Tab key={index} label={opUnit.name} />);

  opUnitsRoles = classes => {
    return (
      <Query
        query={GET_OP_UNITS}
        fetchPolicy={'network-only'}
      >
        {({ data, loading }) => {
          if (loading) return <Loading message="Retrieving Business Units" />;
          const opUnitId = data.opUnits[this.state.opUnit].id;
          return (
            <main className={classes.main} style={{ paddingLeft: 0 }}>
              <Paper className={classes.root}>
                <Tabs
                  value={this.state.opUnit}
                  onChange={this.handleOpUnitChange}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  {this.renderOpUnitTabs(data.opUnits)}
                </Tabs>
              </Paper>
              {this.renderOpUnitRoles(opUnitId)}
            </main>
          );
        }}
      </Query>
    );
  };

  renderContent = classes => {
    switch (this.state.value) {
      case 0:
        return <CompanyRoles />;
      case 1:
        return this.opUnitsRoles(classes);
      default:
        return <CompanyRoles />;
    }
  };
  render() {
    const { classes } = this.props;
    console.log('Rendering Role Management');
    return (
      <main className={classes.main}>
        <Paper className={classes.root}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab key={0} label="Company Roles" />
            <Tab key={1} label="Region Roles" />
          </Tabs>
        </Paper>
        {this.renderContent(classes)}
      </main>
    );
  }
}

ManageCompany.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ManageCompany);
