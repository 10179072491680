// External Imports
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/BusinessCenter';
// Internal Imports
import FormWrapper from '../common/formWrapper';
import PropTypes from 'prop-types';

const styles = theme => ({
  buttonWrapper: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonContent: {
    margin: theme.spacing(1)
  },
  icon: {
    fontSize: 54
  }
});

class Registration extends Component {
  render() {
    const { classes, handleRegisterCompany, handleRegisterUser } = this.props;
    return (
      <div>
        <FormWrapper size="large">
          <h2>Registration</h2>
          <Grid container alignItems="center" justify="center">
            <Grid className={classes.buttonWrapper} item>
              <Button
                onClick={handleRegisterUser}
                className={classes.button}
                variant="raised"
                color="primary"
              >
                <Grid
                  className={classes.buttonContent}
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                >
                  <AssignmentIcon className={classes.icon} />
                  Choose Existing Company
                </Grid>
              </Button>
            </Grid>
            <Grid className={classes.buttonWrapper} item>
              <Button
                onClick={handleRegisterCompany}
                className={classes.button}
                color="primary"
              >
                <Grid
                  className={classes.buttonContent}
                  container
                  alignItems="center"
                  justify="center"
                  direction="column"
                >
                  <BusinessIcon className={classes.icon} />
                  Register New Company
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </FormWrapper>
      </div>
    );
  }
}
Registration.propTypes = {
  classes: PropTypes.object,
  handleRegisterCompany: PropTypes.func,
  handleRegisterUser: PropTypes.func
}
export default withStyles(styles)(Registration);
