// External Imports
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Routes from './routes';
import Views from './view';

const GET_AUTH = gql`
  query Auth {
    auth {
      authenticated
      user {
          id
          firstName
          lastName
      }
      companyPermissions {
        company {
            id
            name
        }
        permissionSet
      }
      officePermissions {
        office {
            id
            name
        }
        permissionSet        
      }
    }
  }
`;

function AppAuth(props) {
    const {state, toggleDrawerList, closeDrawer} = props
    const { loading, refetch, error, data } = useQuery(GET_AUTH);
      console.log(data)
      console.log(error)
      if (loading) {
        return (
          <div
            className="App"
            style={{ backgroundColor: '#eee', height: '100%' }}
          >
            <Views.AppBar auth={false} refetchAuth={refetch} />
          </div>
        );
      } else {
        return (
          <div
            className="App"
            style={{ backgroundColor: '#eee', height: '100%' }}
          >
            <Views.AppBar auth={data.auth.authenticated} refetchAuth={refetch} />
            <Routes
              auth={data.auth}
              refetchAuth={refetch}
              drawerState={state}
              toggleDrawerList={toggleDrawerList}
              closeDrawer={closeDrawer}
            />
          </div>
        )
      }
}


export default AppAuth;
