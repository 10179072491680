import React, { Component } from 'react';
import Form from './form';

class SignOut extends Component {
  render() {
    return (
      // <Query query={GET_SIGNOUT} onCompleted={async response => {refetchAuth();}} >
      //   {() => (
      <div>
        <Form />
      </div>
      // )}
      // </Query>
    );
  }
}
export default SignOut;
