import React from 'react';
import PhoneInput from 'react-phone-input-2'
import PropTypes from 'prop-types';

export default class PhoneField extends React.Component {
    render() {
        return <PhoneInput
            country={'us'}
            color={this.props.input.value}
            onChange={this.props.input.onChange}
        />  
    }
}
PhoneField.propTypes = {
    input: PropTypes.object,
}