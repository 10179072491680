import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
// Internal
import ManagementList from './managementList';


const drawerWidth = '250px';


const styles = theme => ({
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'relative'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    minWidth: 0 // So the Typography noWrap works
  },
  toolbar: theme.mixins.toolbar
});
const drawer = (toggleDrawerList, drawerLists) => (
  <div>
    <ManagementList
      toggleDrawerList={toggleDrawerList}
      drawerLists={drawerLists}
    />
    <Divider />
  </div>
);
class AppDrawer extends React.Component {
  render() {
    const { classes, state, toggleDrawerList, closeDrawer } = this.props;
    const { mobileOpen, drawerLists } = state;
    return (
      <div>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={closeDrawer}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer(toggleDrawerList, drawerLists)}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer(toggleDrawerList, drawerLists)}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}
AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.object,
  refetch: PropTypes.func,
  state: PropTypes.object,
  toggleDrawerList: PropTypes.func,
  closeDrawer: PropTypes.func
};
export default withStyles(styles)(AppDrawer);
