// External Imports
import React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
// Internal
import CreateUserForm from './form';

const GET_COMPANIES = gql`
  query companies {
    companies {
      id
      name
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser(
    $firstName: String!
    $middleName: String
    $lastName: String!
    $email: String!
    $officePhone: String
    $mobilePhone: String
    $title: String
    $password: String!
    $companyId: ID!
  ) {
    createUser(
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      email: $email
      officePhone: $officePhone
      mobilePhone: $mobilePhone
      title: $title
      password: $password
      companyId: $companyId
    ) {
      success
      message
    }
  }
`;

function CreateUser(props) {
  const mutationCompleted = data => {
    props.enqueueSnackbar(data.createUser.message, {
      variant: data.createUser.success ? 'success' : 'error'
    });
  }
  const mutationFailed = data => {
    props.enqueueSnackbar(data.createUser.message, {
      variant: 'error'
    });
  }
  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });
  const { loading, data } = useQuery(GET_COMPANIES);
  const handleFormSubmit = (values, createUser) => {
    console.log(values)
    createUser({
      variables: { ...values }
    });
  };
  if (loading)
    return <p>Loading...</p>
  else
    return (
      <Form
        onSubmit={values => handleFormSubmit(values, createUser)}
        mutators={{
          setFieldData,
          setValue: (args, state, utils) => {
            utils.changeValue(state, args[0], () => args[1]);
          }
        }}
        render={({
          handleSubmit,
          form,
          reset
        }) => (
          <CreateUserForm
            onSubmit={handleSubmit}
            form={form}
            reset={reset}
            companies={data.companies}
          />
        )}
      />
    );
}

CreateUser.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func
}
export default withRouter(withSnackbar(CreateUser));
