// External Imports
import React, { useState } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import createHistory from 'history/createBrowserHistory';
import { LoadScript } from '@react-google-maps/api';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AppAuth from './AppAuth';
// eslint-disable-next-line
import fetch from 'isomorphic-unfetch';
import { SnackbarProvider } from 'notistack';
// Internal Imports
import theme from './utility/theme';
import './App.css';
import api_address from './api/config';
import 'react-phone-input-2/lib/bootstrap.css';
import librariesConst from './config/googleAPILibraries';
const history = createHistory();

const httpLink = createHttpLink({
  uri: `${api_address}/api/`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function App() {
  const [state, setState] = useState({
    drawerLists: {},
    mobileOpen: true
  });
  const toggleDrawerList = drawerName => {
    setState({
      drawerLists: {
        ...state.drawerLists,
        [drawerName]: !state.drawerLists[drawerName]
      }
    });
  };
  const closeDrawer = () => {
    setState({ mobileOpen: !state.mobileOpen });
  };
  return (
    <LoadScript
      id="main-script-loader"
      googleMapsApiKey="AIzaSyDofA3cxei_VEvF1gHyxsYAYXFohA5sV6o"
      libraries={librariesConst}
    >
      <ApolloProvider client={client}>
        <Router history={history}>
          <MuiThemeProvider theme={createMuiTheme(theme)}>
            <SnackbarProvider maxSnack={3}>
              <AppAuth state={state} toggleDrawerList={toggleDrawerList} closeDrawer={closeDrawer}/>
            </SnackbarProvider>
          </MuiThemeProvider>
        </Router>
      </ApolloProvider>
    </LoadScript>
  );
}


export default App;
