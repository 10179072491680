import React from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import Loading from '../common/loading';

const styles = theme => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  },
  wrapper: {
    marginTop: theme.spacing(),
    width: '90%'
  }
});

const UPDATE_AUTHORIZATION = gql`
  mutation updateAuthorization($id: ID!, $authorization: String!) {
    updateAuthorization(id: $id, authorization: $authorization)
  }
`;

const GET_USERS = gql`
  query users {
    users {
      id
      person {
        id
        firstName
        lastName
        authorization {
          id
          authorizationSet
        }
      }
    }
  }
`;

const changeAuthorization = async (updateAuthorization, data, field) => {
  await updateAuthorization({
    variables: { id: data.person.authorization.id, authorization: field }
  });
};
const permissionToggle = (data, field, refetch) => {
  return (
    <Mutation
      mutation={UPDATE_AUTHORIZATION}
      onCompleted={() => {
        refetch();
      }}
    >
      {updateAuthorization => {
        return (
          <Checkbox
            key={`${field}-${data.person.id}`}
            checked={!!data.person.authorization.authorizationSet[field]}
            onChange={() => {
              changeAuthorization(updateAuthorization, data, field);
            }}
          />
        );
      }}
    </Mutation>
  );
};

const renderTable = (data, refetch, classes) => {
  const columns = [
    {
      title: 'First Name',
      field: 'person.firstName'
    },
    {
      title: 'Last Name',
      field: 'person.lastName'
    },
    {
      title: 'Assign Company Permissions',
      field: 'assignCompanyPermissions',
      render: rowData =>
        permissionToggle(rowData, 'assignCompanyPermissions', refetch)
    },
    {
      title: 'Add Asset Types',
      field: 'addAssetTypes',
      render: rowData => permissionToggle(rowData, 'addAssetTypes', refetch)
    },
    {
      title: 'Create Regions',
      field: 'createOpUnits',
      render: rowData => permissionToggle(rowData, 'createOpUnits', refetch)
    },
    {
      title: 'Create HASP',
      field: 'createHASP',
      render: rowData => permissionToggle(rowData, 'createHASP', refetch)
    },
    {
      title: 'Create HASP Template',
      field: 'createHASPTemplate',
      render: rowData =>
        permissionToggle(rowData, 'createHASPTemplate', refetch)
    },
    {
      title: 'Approve HASP',
      field: 'approveHASP',
      render: rowData => permissionToggle(rowData, 'approveHASP', refetch)
    },
    {
      title: 'Create ER',
      field: 'createER',
      render: rowData => permissionToggle(rowData, 'createER', refetch)
    },
    {
      title: 'Create Assessment',
      field: 'createAssessment',
      render: rowData => permissionToggle(rowData, 'createAssessment', refetch)
    },
    {
      title: 'Create Removal',
      field: 'createRemoval',
      render: rowData => permissionToggle(rowData, 'createRemoval', refetch)
    },
    {
      title: 'Add Custom Calibration',
      field: 'addCustomCalibration',
      render: rowData =>
        permissionToggle(rowData, 'addCustomCalibration', refetch)
    }
  ];
  return (
    <div className={classes.wrapper}>
      <MaterialTable
        title="Manage Permissions"
        columns={columns}
        data={data}
        options={{
          paging: false,
          maxBodyHeight: '1000px'
        }}
      />
    </div>
  );
};
const Users = props => {
  const { classes } = props;
  return (
    <Query query={GET_USERS} fetchPolicy={'network-only'}>
      {({ data, loading, refetch }) => {
        if (loading) return <Loading message="Retrieving Business Units" />;
        const orderedData = _.orderBy(
          data.users,
          ['person.lastName', 'person.firstName', 'person.email'],
          ['asc', 'asc', 'asc']
        );
        return renderTable(orderedData, refetch, classes);
      }}
    </Query>
  );
};
Users.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Users);
