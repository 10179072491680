// External Imports
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Form } from 'react-final-form';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import setFieldData from 'final-form-set-field-data';
import { withSnackbar } from 'notistack';
// Internal
import CreateCompanyForm from './form';

const CREATE_COMPANY = gql`
  mutation createCompany(
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
    $placeId: String!
    $phone: String!
    $duns: String!
    $fein: String!
    $primaryColor: String
    $secondaryColor: String
    $logo: String
    $website: String!
  ) {
    createCompany(
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zip: $zip
      placeId: $placeId
      phone: $phone
      duns: $duns
      fein: $fein
      primaryColor: $primaryColor
      secondaryColor: $secondaryColor
      logo: $logo
      website: $website
    ) {
      success
      message
    }
  }
`;

function CreateCompany(props) {
  const mutationCompleted = data => {
    props.enqueueSnackbar(data.createCompany.message, {
      variant: data.createCompany.success ? 'success' : 'error'
    });
  }
  const mutationFailed = data => {
    props.enqueueSnackbar(data.createCompany.message, {
      variant: 'error'
    });
  }
  const [createCompany] = useMutation(CREATE_COMPANY, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });

  const handleFormSubmit = (values, createCompany) => {
    console.log(values)
    createCompany({
      variables: { ...values }
    });
  };
  return (
    <Form
      onSubmit={values => handleFormSubmit(values, createCompany)}
      mutators={{
        setFieldData,
        setValue: (args, state, utils) => {
          utils.changeValue(state, args[0], () => args[1]);
        }
      }}
      render={({
        handleSubmit,
        form
      }) => (
        <CreateCompanyForm
          onSubmit={handleSubmit}
          form={form}
        />
      )}
    />
  );
}

CreateCompany.propTypes = {
  refetchAuth: PropTypes.func,
  history: PropTypes.object,
  enqueueSnackbar: PropTypes.func
}
export default withRouter(withSnackbar(CreateCompany));
