import Drawer from './components/drawer';
import SignUp from './components/signUp';
import SignIn from './components/signIn';
import SignOut from './components/signOut';
import CreateCompany from './components/createCompany';
import Register from './components/registration';
import RegisterUser from './components/registration/invite';
import NoMatch from './components/statusPages/noMatch';
import AppBar from './components/appBar';
import RoleManagementContainer from './components/account/roleManagement';
import ManageUsersContainer from './components/account/manageUsers';
import DashboardContainer from './components/dashboard';

const ManageUsers = Drawer(ManageUsersContainer);
const RoleManagement = Drawer(RoleManagementContainer);
const Dashboard = Drawer(DashboardContainer);

export default {
  SignUp,
  SignIn,
  CreateCompany,
  SignOut,
  NoMatch,
  Register,
  RegisterUser,
  AppBar,
  Dashboard,
  ManageUsers,
  RoleManagement,
};
