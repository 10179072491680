import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Invite from '../invite';
import Users from './users';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  main: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5)
  }
});


function ManageUsers(props) {
  const [state, setState] = useState({
    value: 0
  });

  const handleChange = (event, value) => {
    setState({ value });
  };


  const renderContent = (state) => {
    switch (state.value) {
      case 0:
        return <Users />;
      case 1:
        return <Invite />;
      default:
        return <Invite />;
    }
  };
  const { classes } = props;

  return (
    <main className={classes.main}>
      <Paper className={classes.root}>
        <Tabs
          value={state.value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Users" />
          <Tab label="Send Invites" />
        </Tabs>
      </Paper>
      {renderContent(state)}
    </main>
  );
}
ManageUsers.dispalyName = "ManageUsers"
ManageUsers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withSnackbar(withStyles(styles)(ManageUsers));
