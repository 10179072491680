// External Imports
import React from 'react';
import { Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import PropTypes from 'prop-types';
// External Components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
// Internal Imports
import {
  composeValidators,
  required,
  email,
  minLength
} from '../utility/validators';
import FormWrapper from '../common/formWrapper';
import FormError from '../common/formError';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

const Form = ({
  onSubmit,
  classes,
  submitting,
  error,
  history
}) => (
  <FormWrapper size="standard">
    <form onSubmit={onSubmit} className={classes.formWrapper}>
      <div>
        <h1>Sign In</h1>
        <Grid container alignItems="flex-start" justify="center" spacing={2}>
          <Grid item xs={8}>
            <Field
              fullWidth
              required
              name="email"
              component={TextField}
              type="text"
              label="Email"
              validate={composeValidators(required, email)}
            />
          </Grid>
          <Grid item xs={8}>
            <Field
              fullWidth
              required
              name="password"
              component={TextField}
              type="password"
              label="Password"
              validate={composeValidators(required, minLength(6))}
            />
          </Grid>
          {error && (
            <Grid item>
              <FormError errorText={error} />
            </Grid>
          )}
        </Grid>
        <div className={classes.formButtonWrapper}>
          <Button
            onClick={() => {
              history.push('/passwordreset');
            }}
          >
            Reset Password
          </Button>
          <Button type="submit" disabled={submitting}>
            Submit
          </Button>
        </div>
      </div>
    </form>
  </FormWrapper>
);

Form.propTypes = {
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  history: PropTypes.object,
  error: PropTypes.object
}
export default withStyles(styles)(Form);
