// External Imports
import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
// Internal Imports

const styles = theme => ({
  main: {
    backgroundColor: '#eeeeee',
    width: '100%',
    height: '100%'
  },

  buttonWrapper: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonContent: {
    margin: theme.spacing(1)
  },
  icon: {
    fontSize: 54
  },
  title: {
    flex: 1
  }
});

function Dashboard(props) {
  const { classes } = props;
  return (
    <div className={classes.main}>
      Hello
    </div>
  );
}

Dashboard.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Dashboard);
