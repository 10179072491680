// External Imports
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

// Internal Imports
import Views from '../view';
import PrivateRoute from './privateRoute';

class FullRoutes extends React.Component {
  render() {
    const { auth, refetchAuth, ...rest } = this.props;
    console.log('AUTH', auth);
    return (
      <main style={{ backgroundColor: '#eee', height: 'calc(100% - 64px)' }}>
        <Switch>
          <Route
            exact
            path="/"
            render={props => (
              <Views.SignIn {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            path="/signout"
            render={props => (
              <Views.SignIn {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            exact
            path="/signup"
            render={props => (
              <Views.SignUp {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            exact
            path="/createcompany"
            render={props => (
              <Views.CreateCompany {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            exact
            path="/SignIn"
            render={props => (
              <Views.SignIn {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            path="/invite/:id"
            render={props => (
              <Views.RegisterUser {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            path="/passwordreset"
            render={props => (
              <Views.PasswordReset {...props} refetchAuth={refetchAuth} />
            )}
          />
          <Route
            path="/resetpassword/:id"
            render={props => (
              <Views.ResetPassword {...props} refetchAuth={refetchAuth} />
            )}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            auth={auth}
            Component={Views.Dashboard}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/manage-users"
            auth={auth}
            Component={Views.ManageUsers}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/account-settings"
            auth={auth}
            Component={Views.Settings}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/account-manage"
            auth={auth}
            Component={Views.ManageUsers}
            {...rest}
          />
          <PrivateRoute
            exact
            path="/role-management"
            auth={auth}
            Component={Views.RoleManagement}
            {...rest}
          />
          <Route component={Views.NoMatch} />
        </Switch>
      </main>
    );
  }
}
FullRoutes.propTypes = {
  auth: PropTypes.object,
  refetchAuth: PropTypes.func
};
export default FullRoutes;
