import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  formWrapper: {
    margin: 25
  },
  standard: {
    width: 300
  },
  large: {
    width: 500
  },
  largeMax: {
    maxWidth: 1000
  },
  paper: {
    paddingBottom: theme.spacing(2)
  }
});

class FormWrapper extends Component {
  render() {
    const { classes, children, size, style } = this.props;

    return (
      <div className={classes.formWrapper} style={style}>
        <Grid spacing={0} container alignItems="center" justify="center">
          <Grid
            spacing={0}
            className={classes[size]}
            container
            direction="column"
          >
            <Paper className={classes.paper}>{children}</Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
FormWrapper.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element,
  size: PropTypes.number,
  style: PropTypes.object
}
export default withStyles(styles)(FormWrapper);
