import React from 'react';
import MaterialTable from 'material-table';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import Loading from '../common/loading';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';

const styles = theme => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  },
  wrapper: {
    marginTop: theme.spacing(),
    width: '90%'
  }
});

const UPDATE_PERMISSION = gql`
  mutation updatePermission($id: ID!, $permission: String!) {
    updatePermission(id: $id, permission: $permission)
  }
`;
const GET_USERS_FOR_OP_UNIT = gql`
  query usersForOpUnit($id: ID!) {
    usersForOpUnit(id: $id) {
      id
      person {
        id
        firstName
        lastName
      }
      permission {
        id
        permissionSet
      }
    }
  }
`;
const changePermission = (updatePermission, data, field) => {
  updatePermission({
    variables: { id: data.permission.id, permission: field }
  });
};
const permissionToggle = (data, field, refetch) => {
  return (
    <Mutation mutation={UPDATE_PERMISSION} onCompleted={refetch}>
      {updatePermission => {
        return (
          <Checkbox
            checked={data.permission.permissionSet[field]}
            onChange={() => {
              changePermission(updatePermission, data, field);
            }}
          />
        );
      }}
    </Mutation>
  );
};

const renderTable = (data, refetch) => {
  const orderedData = _.orderBy(
    data,
    ['person.lastName', 'person.firstName', 'person.email'],
    ['asc', 'asc', 'asc']
  );
  const columns = [
    {
      title: 'First Name',
      field: 'person.firstName'
    },
    {
      title: 'Last Name',
      field: 'person.lastName'
    },
    {
      title: 'Add Offices',
      field: 'addOffices',
      render: rowData => permissionToggle(rowData, 'addOffices', refetch)
    },
    {
      title: 'Add Assets',
      field: 'addAssets',
      render: rowData => permissionToggle(rowData, 'addAssets', refetch)
    },
    {
      title: 'Add Asset History',
      field: 'addAssetHistory',
      render: rowData => permissionToggle(rowData, 'addAssetHistory', refetch)
    },
    {
      title: 'Add Asset Deployment',
      field: 'addAssetDeployment',
      render: rowData =>
        permissionToggle(rowData, 'addAssetDeployment', refetch)
    },
    {
      title: 'Add Asset Group',
      field: 'addAssetGroup',
      render: rowData => permissionToggle(rowData, 'addAssetGroup', refetch)
    },
    {
      title: 'Void Asset History',
      field: 'voidAssetHistory',
      render: rowData => permissionToggle(rowData, 'voidAssetHistory', refetch)
    },
    {
      title: 'Transfer Asset',
      field: 'transferAsset',
      render: rowData => permissionToggle(rowData, 'transferAsset', refetch)
    }
  ];
  return (
    <MaterialTable
      title="Manage Permissions"
      columns={columns}
      data={orderedData}
      options={{
        paging: false,
        maxBodyHeight: '1000px'
      }}
    />
  );
};
const Users = props => {
  const { classes, opUnitId } = props;
  if (!opUnitId) return null;
  return (
    <Query
      query={GET_USERS_FOR_OP_UNIT}
      variables={{ id: opUnitId }}
      fetchPolicy={'network-only'}
    >
      {({ data, loading, refetch }) => {
        if (loading) return <Loading message="Retrieving Users" />;
        return (
          <div className={classes.wrapper}>
            {renderTable(data.usersForOpUnit, refetch)}
          </div>
        );
      }}
    </Query>
  );
};
Users.propTypes = {
  classes: PropTypes.object.isRequired,
  opUnitId: PropTypes.string
};

export default withStyles(styles)(Users);
