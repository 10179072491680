// External Imports
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
// External Components
import { TextField } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

// Internal Imports
import FormWrapper from '../../common/formWrapper';
import {
  composeValidators,
  required,
  email,
  minLength
} from '../../utility/validators';

const styles = () => ({
  formButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 20
  }
});

class Form extends Component {
  render() {
    const { classes, onSubmit, pristine, reset, submitting } = this.props;

    return (
      <FormWrapper size="standard">
        <form onSubmit={onSubmit} className={classes.formWrapper}>
          <h1>Complete Invite</h1>
          <Grid spacing={2} container alignItems="center" justify="center">
            <Grid item xs={8}>
              <Field
                label="First Name"
                name="firstName"
                fullWidth
                component={TextField}
                placeholder="First Name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                label="Last Name"
                name="lastName"
                fullWidth
                component={TextField}
                placeholder="Last Name"
                validate={required}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Email"
                name="email"
                component={TextField}
                placeholder="Enter a Email"
                disabled={true}
                validate={composeValidators(required, email)}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Phone"
                name="phone"
                component={TextField}
                placeholder="Enter a Phone Number"
                validate={composeValidators(required)}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Password"
                name="password"
                type="password"
                component={TextField}
                placeholder="Enter a Password"
                validate={composeValidators(required, minLength(6))}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                fullWidth
                label="Confirm Password"
                name="confirmPassword"
                type="password"
                component={TextField}
                placeholder="Confirm Password"
                validate={composeValidators(required, minLength(6))}
              />
            </Grid>
            {/* <Grid item xs={8}>
              <FormControlLabel
                control={<Field name="agreeToTerms" component={Checkbox} />}
                label="Agree to terms?"
              />
            </Grid> */}
          </Grid>
          <div className={classes.formButtonWrapper}>
            <Button disabled={pristine || submitting} onClick={reset}>
              Clear Values
            </Button>
            <Button type="submit" disabled={submitting}>
              Submit
            </Button>
          </div>
        </form>
      </FormWrapper>
    );
  }
}
Form.propTypes = {
  classes: PropTypes.object,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  reset: PropTypes.func,
  submitting: PropTypes.bool
}
export default withStyles(styles)(Form);
