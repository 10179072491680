const theme = {
  palette: {
    primary: {
      light: '#547c99',
      main: '#25506b',
      dark: '#002840'
    },
    secondary: {
      light: '#ffde66',
      main: '#d6ad34',
      dark: '#a17e00'
    }
  }
};
export default theme;
