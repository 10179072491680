import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { gql, useMutation } from '@apollo/client';
import Logo from '../../../appbarlogo.png';
import api_address from '../../../api/config';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBarDev: {
    backgroundColor: theme.palette.primary.main
  },
  appBar: {
    backgroundColor: theme.palette.primary.main
  },
  title: {
    flex: 1,
    textAlign: 'left',
    position: 'relative',
    right: '25px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  logo: {
    color: 'white',
    width: '80px',
    height: '70px'
  },
  link: {
    color: 'white',
    textDecoration: 'none'
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  logoContainer: {
    width: '80px',
    position: 'relative',
    flex: 1,
    textAlign: 'left'
  }
});

const SIGNOUT = gql`
mutation signout {
  signout {
    success
    message
    data
  }
}
`;
function MenuAppBar(props) {
  const { openDrawer, classes, auth, refetchAuth } = props;
  const mutationCompleted = async (data) => {
    props.enqueueSnackbar(data.signout.message, {
      variant: data.signout.success ? 'success' : 'error'
    });
    localStorage.setItem('token', null);
    await refetchAuth()
  }
  const mutationFailed = data => {
    props.enqueueSnackbar(data.signout.message, {
      variant: 'error'
    });
  }
  const [signout] = useMutation(SIGNOUT, {
    onCompleted: data => mutationCompleted(data),
    onError: data => mutationFailed(data)
  });
  let environment;
  switch (api_address) {
    case 'https://finback-yellowstone-staging.herokuapp.com':
      environment = 'STAGING';
      break;
    case 'http://localhost:8000':
      environment = 'DEVELOPMENT';
      break;
    default:
      environment = 'Production';
  }
  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        className={
          environment !== 'Production' ? classes.appBarDev : classes.appBar
        }
        position="static"
      >
        <Toolbar>
          {auth && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={openDrawer}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className={classes.logoContainer}>
            <img src={Logo} alt="Logo" className={classes.logo} />
          </div>
          {(environment === 'STAGING' || environment === 'DEVELOPMENT') && (
            <Typography
              variant="h6"
              color="inherit"
              className={classes.title}
            >
              {`${environment}`}
            </Typography>
          )}
          {auth && (
            <div className={classes.flexEnd}>
              <Link className={classes.link} to="/signin">
                <Button
                  onClick={signout}
                  color="inherit"
                >
                  Sign Out
                      </Button>
              </Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  openDrawer: PropTypes.func,
  auth: PropTypes.object,
  refetchAuth: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  history: PropTypes.object
};

export default withStyles(styles)(withSnackbar(MenuAppBar));
