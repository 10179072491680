// External Imports
import React from 'react';
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useQuery, useMutation, gql } from '@apollo/client';
import _ from 'lodash';
// Internal Imports
import Loading from '../common/loading';

const DELETE_USER = gql`
  mutation deleteUser(
    $id: ID!
  ) {
    deleteUser(
      id: $id
    ) {
      success
      message
    }
  }
`;

const USERS = gql`
  query users {
    users {
        id
        firstName
        middleName
        lastName
        email
        officePhone
        mobilePhone
        title
        offices {
            name
            id
        }
        company {
            name
            id
        }
    }
  }
`;

const styles = theme => ({
    table: {
        marginTop: theme.spacing(1)
    },
    buttonContent: {
        margin: theme.spacing(1)
    },
});

const officeString = offices => {
    let officeString = ''
    let officeLength = offices.length
    offices.forEach((office, index) => {
        officeString += office.name
        if (index < officeLength)
            officeString += ', '
    })
    return officeString
}

function Users(props) {
    const deletUserCompleted = data => {
        props.enqueueSnackbar(data.deleteUser.message, {
            variant: data.deleteUser.success ? 'success' : 'error'
        });
    }
    const deleteUserFailed = data => {
        props.enqueueSnackbar(data.deleteUser.message, {
            variant: 'error'
        });
    }
    const [deleteUser] = useMutation(DELETE_USER, {
        onCompleted: data => deletUserCompleted(data),
        onError: data => deleteUserFailed(data)
    });
    const { loading, data } = useQuery(USERS);
    if (loading) {
        return <Loading />
    } else {
        const users = _.cloneDeep(data.users);
        console.log(users)
        return (
            <MaterialTable
                title="Users"
                style={{marginTop: '32px'}}
                actions={[
                    {
                        icon: 'delete',
                        tooltip: 'Delete User',
                        onClick: (event, rowData) => {
                            event.preventDefault()
                            deleteUser({ variables: { id: rowData.id } })
                        }
                    }
                ]}
                columns={[
                    { title: "First Name", field: "firstName" },
                    { title: "Middle Name", field: "middleName" },
                    { title: "Last Name", field: "lastName" },
                    { title: "Title", field: "title" },
                    { title: "Email", field: "email" },
                    { title: "Office Phone", field: "officePhone" },
                    { title: "Mobile Phone", field: "mobilePhone" },
                    { title: "Company", render: rowData => rowData.company && rowData.company.name },
                    { title: "Offices", render: rowData =>  officeString(rowData.offices)},
                ]}
                data={users}
            />
        )
    }
}

Users.propTypes = {
    classes: PropTypes.object,
    enqueueSnackbar: PropTypes.func
}

export default withStyles(styles)(Users);
