import React from 'react';
import Drawer from './drawer';
import Snackbar from '../snackbar';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/display-name
const AppDrawer = ContentComponent => props => {
  // eslint-disable-next-line react/prop-types
  const { toggleDrawerList, closeDrawer, drawerState } = props;
  return (
    <div style={{ display: 'flex', height: '100%', backgroundColor: 'white' }}>
      <Drawer
        toggleDrawerList={toggleDrawerList}
        closeDrawer={closeDrawer}
        state={drawerState}
        {...props}
      />
      <div
        style={{
          width: 'calc(100% - 250)',
          flex: 7,
          backgroundColor: '#eeeeee',
          marginRight: 16,
          overflow: 'scroll'
        }}
      >
        <ContentComponent {...props} />
        <Snackbar />
      </div>
    </div>
  );
};

AppDrawer.displayName = 'AppDrawer'

AppDrawer.propTypes = {
  toggleDrawerList: PropTypes.func,
  closeDrawer: PropTypes.func,
  drawerState: PropTypes.object
}
export default AppDrawer;
