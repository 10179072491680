let api_address;
const dev = false;
const staging = false;

if (dev) {
  api_address = 'http://localhost:8000';
} else if (staging) {
  api_address = 'https://parche-staging.herokuapp.com';
} else {
  api_address = 'https://parche.herokuapp.com';
}

export default api_address;
