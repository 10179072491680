import React from 'react';
import { CompactPicker } from 'react-color';
import PropTypes from 'prop-types';

export default class ColorField extends React.Component {
    render() {
        return <CompactPicker
            color={this.props.input.value}
            onChange={this.props.input.onChange}
        />  
    }
}
ColorField.propTypes = {
    input: PropTypes.object,
}