import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
// Internal
import InviteForm from './form';

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  title: {
    flex: 1,
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    color: 'gray'
  },
  statusContainer: {
    width: 300,
    margin: 25
  },
  inviteContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const GET_INVITE = gql`
  query Invite($id: ID!) {
    invite(id: $id) {
      id
      companyId
      person {
        firstName
        lastName
        email
        phone
      }
    }
  }
`;
const REGISTER = gql`
  mutation register(
    $email: String!
    $password: String!
    $phone: String!
    $firstName: String!
    $lastName: String!
    $inviteId: String!
  ) {
    register(
      email: $email
      phone: $phone
      password: $password
      firstName: $firstName
      lastName: $lastName
      inviteId: $inviteId
    )
  }
`;
class Invite extends Component {
  render() {
    const { classes, match, refetchAuth } = this.props;
    const handleFormSubmit = (values, register) => {
      const { email, password, firstName, lastName, phone } = values;
      const inviteId = match.params.id;
      register({
        variables: { email, password, firstName, lastName, phone, inviteId }
      });
    };
    return (
      <div className={classes.inviteContainer}>
        <Query
          query={GET_INVITE}
          variables={{ id: match.params.id }}
          onError={() => {}}
          fetchPolicy={'network-only'}
        >
          {({ data, loading }) => {
            if (loading) return null;
            return (
              <Mutation
                mutation={REGISTER}
                onCompleted={async () => {
                  await refetchAuth();
                  this.props.history.push('/dashboard');
                }}
                onError={() => {
                  this.setState({ formError: 'Sign Up Error' });
                }}
              >
                {register => {
                  return (
                    <Form
                      onSubmit={values => handleFormSubmit(values, register)}
                      initialValues={data && data.invite.person}
                      render={({ handleSubmit }) => (
                        <InviteForm onSubmit={handleSubmit} />
                      )}
                    />
                  );
                }}
              </Mutation>
            );
          }}
        </Query>
      </div>
    );
  }
}
Invite.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  refetchAuth: PropTypes.func,
  history: PropTypes.object

}
export default withSnackbar(withRouter(withStyles(styles)(Invite)));
